<template>
  <div id="PlanoContaModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="text-white title-page body-1">Cadastro de Plano de Conta</span>
          <v-btn
            icon
            dark
            color="#F2F6F7"
            @click="fechar()">
            <v-icon large class="title">
              mdi-close
            </v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text class="pt-1 mb-6">
        <v-container>
          <v-form 
            class="form-container"
            ref="form"
            v-model="valid">
            <v-row>
              <v-col 
                cols="12"
                class="px-0">
                <v-autocomplete
                  v-model="dados.item_pai"
                  :items="opcoes_plano_conta"
                  label="Itens cadastrados"
                  item-text="text"
                  item-key="cod_planoconta"
                  filled 
                  outlined 
                  clearable
                  return-object
                  :rules="descricaoRules"
                  dense 
                  @change="changeItemCadastrado()"
                  background-color="#FFF" 
                  required
                  :key="key_Pai">
                  <template v-slot:selection="data">
                    <span>{{data.item.text}}</span>
                  </template>
                  <template v-slot:item="data">
                    <span
                      :style="{'margin-left': (data.item.planoconta_codigocontabil.split('.').length-1)*20+'px' }">
                      {{data.item.text}}
                    </span>
                  </template>
                </v-autocomplete>
                
                <div  v-if="dados.item_pai">
                  <div style="display: flex;align-items: baseline;">
                    <label style="font-size: 14px; margin-right: 2px;"><b>{{ codigocontabil_aux }}.</b></label>

                    <!-- <pre>{{ dados }}</pre> -->
                    <v-text-field
                      v-model="dados.planoconta_codigocontabil"
                      class="mb-0 "
                      light
                      background-color="#FFF"
                      label="Código Contábil"
                      autofocus
                      placeholder="Código Contábil"
                      filled
                      clearable
                      :rules="codigoRules"
                      maxlength="100"
                      outlined
                      required
                      dense >
                    </v-text-field>
                  </div>

                  <v-text-field
                    v-model="dados.planoconta_descricao"
                    class="mb-0 "
                    light
                    background-color="#FFF"
                    label="Descrição"
                    placeholder="Descrição"
                    filled
                    clearable
                    :rules="descricaoRules"
                    maxlength="100"
                    outlined
                    required
                    dense />
  
                  <v-select
                    v-model="dados.planoconta_situacao"
                    :items="opcoesSituacao"
                    no-filter
                    label="Ativo"
                    item-text="name" 
                    item-value="value"
                    filled 
                    outlined 
                    dense 
                    background-color="#FFF" 
                    required/>
  
                  <v-select
                    v-model="dados.planoconta_analiticasintetica"
                    :items="opcoesAnaliticaSintetica"
                    no-filter
                    label="Analítica/Sintética"
                    item-text="name" 
                    item-value="value"
                    filled 
                    outlined 
                    dense 
                    background-color="#FFF" 
                    required/>
  
                  <v-select
                    v-model="dados.planoconta_creditodebito"
                    :items="opcoesCreditoDebito"
                    no-filter
                    label="Crédito/Débito"
                    item-text="name" 
                    item-value="value"
                    filled 
                    outlined 
                    dense 
                    background-color="#FFF" 
                    required/>
  
                  <v-select
                    v-model="dados.planoconta_fluxocaixa"
                    :items="arraySIM_NAO"
                    no-filter
                    label="Fluxo de Caixa"
                    item-text="name" 
                    item-value="value"
                    filled 
                    outlined 
                    dense 
                    background-color="#FFF" 
                    required/>
                </div>
                </v-col>
              </v-row>
              
            </v-form>
        </v-container>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="fechar()"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text>
          Cancelar
        </v-btn>

        <v-btn
          :disabled="!valid"
          :loading="loading"
          @click="validate()"
          class="btn white--text caption font-weight-medium"
          color="primary accent-4">
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import store_site from "../../../store/store_site"
import { API } from '../../../services/API'

import { COR_PRINCIPAL, arraySIM_NAO } from "../../../services/constantes";
import { nextTick } from 'vue';

export default {
  name: "PlanoContaModal",

  // props: [],
  props: {
    array_plano_conta: { type: Array },
    dados_editar: { type : Object }
  },

  data() {
    return {
      
      store_site        : store_site,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      loading           : false,
      search            : null,
      arraySIM_NAO      : arraySIM_NAO,
      opcoes_plano_conta : [],
      codigocontabil_aux : null,
      dados : {
        item_pai                      : null,
        planoconta_codigocontabil     : null,
        planoconta_descricao          : null,
        planoconta_situacao           : 'A',
        planoconta_analiticasintetica : 'A',
        planoconta_creditodebito      : 'C',
        planoconta_fluxocaixa         : 'S',
      },
      valid: true,
      form: false,
      key_Pai: 0,

      opcoesAnaliticaSintetica : [
        { name: "Análitica", value: "A" },
	      { name: "Sintética", value: "S" },
      ],

      opcoesSituacao : [
        { name: "Sim", value: "A" },
	      { name: "Não", value: "I" },
      ],

      opcoesCreditoDebito : [
        { name: "Crédito", value: "C" },
	      { name: "Débito", value: "D" },
      ],
   
      descricaoRules: [
        (value) => !!value || "A Descrição é obrigatória",
      ],
      codigoRules: [
        (value) => !!value || "O Código Contábil é obrigatório",
        (value) => (/^\d+$/.test(value)) || "Digite apenas números"
      ],
      headers: [
        { text : '', value:'' },
        { text : 'Cód. contabil', value:'cod_contabil' },
        { text : 'Descrição', value:'descricao' },
      ],
    
    };
  },

  computed: {
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    }
  },

  async mounted(){
    this.opcoes_plano_conta = [...this.array_plano_conta]
    if(this.dados_editar?.acao == 'editar'){
      this.dados = {...this.dados_editar}
      let i = this.opcoes_plano_conta.findIndex(val => val.cod_planoconta == this.dados.cod_planoconta)

      if(i){
        this.opcoes_plano_conta.splice(i, 1)
      }

      const pai = await this.achandoPai()
      this.dados.item_pai = pai;
      this.key_Pai++;
      let codigocontabil = this.dados_editar.planoconta_codigocontabil.split('.')
      this.dados.planoconta_codigocontabil = codigocontabil[codigocontabil.length -1]
      this.codigocontabil_aux = this.dados?.item_pai?.planoconta_codigocontabil 
    }
  },

  methods: {
    changeItemCadastrado(){
      this.codigocontabil_aux = this.dados.item_pai.planoconta_codigocontabil
    },

    async achandoPai(){
      var la_achando_pai = this.dados.planoconta_codigocontabil.split('.')
      var pai_encontrado = ''
      for (let index = 0; index < la_achando_pai.length -1; index++) {
        const element = la_achando_pai[index];
        pai_encontrado += element + '.'
      }
      pai_encontrado = pai_encontrado.slice(0, -1);
      
      var lo_item_cadastrado = await this.opcoes_plano_conta.find(val => val.planoconta_codigocontabil === pai_encontrado)
      if(!lo_item_cadastrado){
        lo_item_cadastrado = this.opcoes_plano_conta[0]
      }
      return lo_item_cadastrado
    },

    fechar(){
      this.$emit("update:dialog_PlanoContaModal", false);
    },

    async validate () {
      const lb_valido = this.$refs.form.validate();
      
      if (lb_valido) {
        this.dados.planoconta_codigocontabil = `${this.dados.item_pai.planoconta_codigocontabil}.${this.dados.planoconta_codigocontabil}`
        this.loading = true

        let ls_Rel = null
        if(this.dados?.acao === 'editar'){
          ls_Rel = await API.put(`/erp/plano_conta/${this.dados.cod_planoconta}`, JSON.stringify(this.dados));
        } 
        else {
          ls_Rel = await API.post(`/erp/plano_conta`, JSON.stringify(this.dados));
        }
        this.loading = false
        this.fechar()
        if (ls_Rel.status == 200) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Rel.data.result.result.trim();
          this.store_site.alert           = true;

          this.$emit('busca')
        } else {
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = ls_Rel.data.result.result.trim();
          this.store_site.alert           = true;
        }

        this.$emit('atualizarPlanoConta', ls_Rel.data.result.data)
      }
    }
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  /* height: 450px; */
  overflow-y: auto;
}

.btn {
  width: 100px;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 
</style>