<template>
<div id="PlanoContas" class="container-fundo pa-0" v-bind:style="{ 'background-color':COR_PRINCIPAL }">

      <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">
        <!-- Título da página -->
        <div class="justify-space-between d-flex">
          <v-toolbar-title class="text-white title-page mt-2 ml-4 ">Plano de Contas</v-toolbar-title>

          <v-btn v-if="acao == 'SELECIONAR'" @click="fechar()" icon dark color="#F2F6F7" class="mr-2 mt-2 mb-n2">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
        </div>

        <!-- Botões de ação cabeçalho -->
        <v-toolbar-title class="mt-1 mb-6 mr-4 ml-4 pa-0" dark elevation="0">
          <div class="d-flex " tabindex="-1">
            <v-text-field
              id="txtBuscar"
              ref="txtBuscar"
              autocomplete="off"
              autofocus
              prepend-inner-icon="mdi-magnify"
              dense
              v-model="search"
              cache-items
              class="search-input"
              flat
              clearable
              dark
              label=""
              solo-inverted
              tabindex="-1">
          </v-text-field>
            <!-- Text Field com Consulta ----------------------------------------->
            
            <v-spacer></v-spacer>
            <!-- Botão Novo ------------------------------- -->
            <v-btn
              v-on:click="novo()"
              class="text-white ml-5 mt-1 ContasPagar_novo"
              :color="COR_PRINCIPAL"
              elevation="0"
              :small="isMobile">
              <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
              <span>Novo</span>
            </v-btn>
            
          </div>
        </v-toolbar-title>
        <!-- Fim Botões de ação cabeçalho -->

      </v-toolbar-title>


      <v-row class=" mt-n11 w-100 mx-0">
        <!-- Grade ----------------------------------->
        <v-col cols="12" class="pa-0 mx-0 w-100">
          <v-container class="container-principal container-rounded px-0 w-100 mx-0">

            <!-- <pre>{{ array_plano_conta }}</pre> -->
            <v-data-table
              :headers="headers"
              :items="array_plano_conta"
              fixed-header
              hide-default-footer
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado. Use os campos acima para refinar sua consulta"
              no-results-text="Nenhum Registro Encontrado. Use os campos acima para refinar sua consulta`"
              item-key="cod_planoconta"
              no-sorting
              :height="tableHeight"
              :loading="loading"
              :search="search"
            >
              <template #item="{ item }">
                <tr>
                  <td style="width: 5%;">
                    <v-menu
                    v-if="item.planoconta_codigocontabil.split('.').length > 1"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="grey"
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="py-0">
                        <v-list-item
                          v-for="(item_menu, i) in items"
                          :key="i"
                          @click="() => {}"
                          class="px-2"
                        >
                        
                          <v-list-item-title
                            v-if="item_menu.title == 'Editar'"
                            @click="editar(item)">                          
                              <v-icon
                                color="green"
                                class="mr-2 icon-menu">
                                mdi-pencil-outline
                              </v-icon>
                            {{ item_menu.title }}
                          </v-list-item-title>

                          <v-list-item-title
                            v-else-if="item_menu.title == 'Excluir'">
                            <div
                              @click="dialogExcluir(item)">
                              <v-icon
                                color="red"
                                class="mr-2 icon-menu">
                                mdi-delete-outline
                              </v-icon>
                              {{ item_menu.title }}
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                  <td>
                    <span
                      :style="{'margin-left': (item.planoconta_codigocontabil.split('.').length-1)*20+'px' }">
                      {{item.planoconta_codigocontabil}}
                    </span>
                    <!-- {{ item.planoconta_codigocontabil }} -->
                  </td>
                  <td>
                    {{ item.planoconta_descricao }}
                  </td>
                  <td>
                   {{ item.planoconta_situacao == 'A' ? 'Ativo' : 'Inativo' }}
                  </td>
                  <td>
                    {{ item.planoconta_analiticasintetica == 'S' ? 'Sintética' : 'Analítica'  }}
                  </td>
                  <td>
                    {{ item.planoconta_creditodebito == 'C' ? 'Crédito' : 'Débito' }}
                  </td>
                  <td>
                    {{ item.planoconta_fluxocaixa == 'S' ? 'Sim' : 'Não' }}
                  </td>
                </tr>
              </template>
            </v-data-table>

          </v-container>
        </v-col>
        <!-- Fim Grade ----------------------------------->
      </v-row>

      <!-- Dialog Excluir ----------------------- -->
      <v-dialog
        :value="dialog_excluir"
        persistent
        max-width="430"
        class="d-flex justify-center">
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center">
        <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
            <span class="white--text title-page body-1">Excluir Plano de Conta</span>
              <v-btn @click="dialog_excluir = null" icon dark color="#F2F6F7">
                <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
        <v-card-title class="text-h6">
          Tem certeza que deseja EXCLUIR ?
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir = null">
            Cancelar
          </v-btn>

          <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="excluir()">
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Novo/Editar -->
    <v-container v-if="dialog_PlanoContaModal">
      <v-dialog
        persistent
        v-model="dialog_PlanoContaModal"
        transition="dialog-bottom-transition"
        max-width="500">
        <PlanoContaModal
          :dialog_PlanoContaModal.sync="dialog_PlanoContaModal"
          :array_plano_conta="array_plano_conta"
          :dados_editar="dados_editar"
          @busca="grade()"/>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import PlanoContaModal from "../ContasPagar/PlanoContaModal.vue"
import { baseURL, API } from "../../../services/API";
import store_usuario from "../../../store/store_usuario";
import store_ContasPagar from "../ContasPagar/store_ContasPagar";
import store_site from "../../../../src/store/store_site"
import store_Empreendimento from "../../Empreendimentos/store_Empreendimento";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SECUNDARIA_2, COR_SUBTITULO, COR_BORDA } from "../../../services/constantes";
import moment, { now } from 'moment';
import { saveAs } from 'file-saver';
import { AbreRelatorioExterno, Relatorio } from "../../../services/global";
import exportFromJSON from "export-from-json";
//---------------------- fim teste download excel --------

export default {
  name: "PlanoContas",

  props: ['acao', 
          'dialog_ContasPagar', 
          'itens_array_pai', 
          'parc_valor', 
          'parc_vencto', 
          'dialog_lancamentos', 
          'cedente_selecionado'],

  components: {
    PlanoContaModal
  },

  data() {
    return {
      user : { username : null }, 

      array_plano_conta      : [],
      search                 : '',
      dialog_PlanoContaModal : false,
      dados_editar           : null,


 
      store_usuario                        : store_usuario,
      store_ContasPagar                    : store_ContasPagar,
      store_site                           : store_site,
 
      loading                              : false,
  
      COR_PRINCIPAL                        : COR_PRINCIPAL,
      COR_SECUNDARIA                       : COR_SECUNDARIA,
      COR_SECUNDARIA_2                     : COR_SECUNDARIA_2, 
      COR_SUBTITULO                        : COR_SUBTITULO,
      COR_BORDA                            : COR_BORDA,
      accentColor                          : '#f00',
 
      dialog_excluir                       : false,
     
      /* Título container-rounded */
      headers: [
        { text: "", value: "cod_planoconta", style: "", sortable: false, },
        { text: "Cód. contabil", value: "planoconta_codigocontabil", style: "", sortable: false, },
        { text: "Descrição", value: "planoconta_descricao", style: "", sortable: false, },
        { text: "Situação", value: "planoconta_situacao", style: "", sortable: false, },
        { text: "Analítica/Sintética", value: "planoconta_analiticasintetica", style: "", sortable: false, },
        { text: "Crédito/Débito", value: "planoconta_creditodebito", style: "", sortable: false, },
        { text: "Fluxo caixa", value: "planoconta_fluxocaixa", style: "", sortable: false, },
      ],
            /* Menu Edição (Button Dots) */
            items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir", 
        },
      ],
    };
  },

  mounted() {
    this.grade()
    // Seta os privilegios dos elementos da apícãõ inteira
    this.store_usuario.priv_seta_privilegios_aplicacao(this.$options.name);
    this.empreendimento_privilegio_acesso();
  },
  
  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 145;
      else
        return window.innerHeight - 149 - 30;
    },
  },

  methods: {
    dialogExcluir(item){

      // var la_achando_pai = item.planoconta_codigocontabil.split('.')
      // var pai_encontrado = ''
      // for (let index = 0; index < la_achando_pai.length -1; index++) {
      //   const element = la_achando_pai[index];
      //   pai_encontrado += element + '.'
      // }
      // pai_encontrado = pai_encontrado.slice(0, -1);

    
      const itemParaExcluir = item.planoconta_codigocontabil 

      let podeExcluir = true;

      this.array_plano_conta.forEach(val => {
        if (val.planoconta_codigocontabil !== itemParaExcluir && val.planoconta_codigocontabil.includes(itemParaExcluir)) {
            // console.log("🚀 ~foreach", val.planoconta_codigocontabil, ' = ', itemParaExcluir, val.planoconta_codigocontabil !== itemParaExcluir && val.planoconta_codigocontabil.includes(itemParaExcluir))
              // Se a string "1.1" estiver contida em algum outro elemento, não pode excluir
              podeExcluir = false;
          }
      });

      if (!podeExcluir) {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'Você deve excluir todas as dependências deste plano de conta primeiro.'
        this.store_site.alert           = true;
        return
      }

// return
//       var existe_pai = this.array_plano_conta.find(val => val.planoconta_codigocontabil == pai_encontrado)

//       if(!!existe_pai){
//         this.store_site.alert_cor       = "#FF0000";
//         this.store_site.alert_timeout   = 10000;
//         this.store_site.alert_msg       = 'O pai deste plano de conta deve ser excluído primeiro!'
//         this.store_site.alert           = true;
//         return
//       }
      this.dialog_excluir = true; 
      this.dialog_excluir = item.cod_planoconta
    },

    async grade(){
      let {data} = await API.get('/erp/plano_conta_grade')
      this.array_plano_conta = data.result || []
      this.array_plano_conta.forEach(elem => {
        elem.text = `${elem.planoconta_codigocontabil} - ${elem.planoconta_descricao}`
      })
      // console.log("🚀 ~ file: PlanoContas.vue:335 ~ grade ~ this.array_plano_conta:", this.array_plano_conta)
    },
    
    fechar() {
      this.$emit('update:dialog_ContasPagar', null);
    },


    async empreendimento_privilegio_acesso() {
      const resp = await API.get(`empreendimento_privilegio_acesso`);
      if (resp) {
        var la_Resp = resp.data.result;
        this.empreendimentos = la_Resp;
      }
    },

    // Função para enviar dados para o filho ContasPagarModal.vue e realizar a edição
    async editar(item) {
      this.dados_editar = item
      this.dados_editar.acao = 'editar'
      this.dialog_PlanoContaModal = true
    },

    async excluir(){
      let ls_Res = await API.delete(`/erp/plano_conta/${this.dialog_excluir}`)
      this.dialog_excluir = null;

      if (ls_Res.status == 200) {
        // Mensagem de exclusão confirmada
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = ls_Res.data.result.result.trim();
        this.store_site.alert           = true;
        this.grade()
      } else {
        // Mensagem de exclusão não realizada
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = ls_Res.data.result.trim();
        this.store_site.alert           = true;
      }
    },

    async novo(){
      this.dados_editar           = {};
      this.dialog_PlanoContaModal = true;
    },
  },
}  

</script>

<style scoped>

  .title-page {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .date-picker {
    max-width: 200px!important;
  }

  .table tr {
    width: 100%;
  }

  .table-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background: #fff;
  }

  /* .table-row:hover {
    background: #f6f6f6 !important;
  } */

  .v-btn {
    display: inline-block;
  }

  /*-----------------------------------*/
  /*------ RESPONSIVE GENERAL ------*/
  /*-----------------------------------*/
  .v-toolbar,
  .v-sheet {
    display: flex !important;
    flex-direction: column !important;
    padding: 0px !important;
  }

  .v-toolbar__content {
    padding-left: 0px !important;
  }

  .v-toolbar__title {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .icon-action {
    font-size: 1.3rem !important;
  }

  .container-principal {
    max-width: 100%;
    margin: auto !important;
  }

  .container-fundo {
    height: calc(100vh -30px) !important;
  }

  .container-rounded {
    background: #fff !important;
    border-radius: 12px !important;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 1.5rem !important;
    height: 22px !important;
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-impressos-fundo {
    background-color: rgb(235, 235, 239) !important;
  }

  .data-table-impressos > .v-data-table__wrapper > table > thead > tr > th  {
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-impressos {
    background-color: rgb(255, 255, 255) !important;
  }

  .data-table-principal {
    background-color: rgb(255, 255, 255) !important;
  }

  .data-table-principal > .v-data-table__wrapper > table > thead > tr > th  {
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-principal > .v-data-table__wrapper > table > tbody > tr > th  {
    background-color: rgb(2, 245, 245) !important;
    height: 20px !important;
  }

  #virtual-scroll-table-tablet {
    display: none;
  }

  /* .table {
    display: block;
    table-layout: fixed;
    overflow: hidden;
    width: 100% !important;
    min-height: 300px !important;
  }

  .table-td {
    display: block;
    table-layout: fixed;
    overflow: hidden;
    width: 100% !important;
    min-height: 330px !important;
    height: 30px !important;
  } */

  .card-container {
    padding: 16px!important;
  }

  .false-filter {
    background-color:#6C87A2;
    height: 38px!important;
    min-width: 400px!important;
    /* max-width: 900px!important; */
    border-radius: 5px;
    position: relative;
  }

  .false-text {
    width: calc(100%);
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    color: #C9CBCA;
    cursor: pointer;
    overflow-x: hidden;
  }

  .false-icon {
    cursor: pointer;
  }

  .v-select__slot {
    display: none !important;
  }

  .checkbox {
    margin-bottom: 3px;
    margin-top: 1px;
  }

  /* ::v-deep .v-data-footer {
    align-content: center;
    color: var(--COR_PRINCIPAL) !important;  
    font-size:14px !important;
    height: 35px;
    padding-top: 10px !important;
  }

  ::v-deep .v-data-footer__select {
    display: none;
  }
 */

 .status_aberto_vencer {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: rgb(242, 192, 98);
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }
  .status_aberto_vencida {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: #FF5252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }  

  .status_pago {
    font-size: 12px !important;
    background: #469c47;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_inativo {
    font-size: 12px !important;
    background: #525252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_rescindido {
    font-size: 12px !important;
    background: #A2A2A2;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }


  .width-nome {
    display: block;
    min-width: 250px;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    z-index: 3;
  }

  .width-parcela {
    min-width: 120px;
    white-space: nowrap;
  }

  .width-nome-customer {
    white-space: nowrap;
  }

  .width-empreendimento {
    display: block;
    min-width: 200px;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    z-index: 3;
  }

  .linhas {
    height:55px;
    background-color:transparent;
  }

  .linhas2 {
    height:55px;
    background-color:transparent;
  }

  /* PEGA OS ESTILOS DO BLOCO ACIMA E MUDA SÓ O PADDING */
  .status_legend {
    font-size: 12px !important;
    font-weight: 400!important;
    padding: 2px 2px 2px 12px ;
    border-radius: 13px !important;
  }

  .status_legend:hover {
    opacity: 0.5;
  }

  .status_legend_a_vencer {
    background: rgb(242, 192, 98);
  }

  .status_legend_vencidas {
    background: #FF5252;
  }

  .status_legend_pagos {
    background: #469c47;
  }

  /*
  .status_aberto_dia_borda {
    border: 2px solid rgb(1, 168, 1) 
  }

  .status_aberto_atraso_borda {
    /* border: 2px solid rgb(1, 168, 1) 
  }
  */
  .status_a_vencer_atraso_color {
      color: red;
  }

  .status_baixado {
    background: #EF5350;
  }

  .status_pago-atraso {
    background: #FFA726;
  }

  .status_nao-encontrado {
    background: #7E57C2;
  }

  .status_msg-banco {
    background: #BDBDBD;
  }

  .status_invalido {
    background: #F44336;
  }

  /* table.v-table tbody td, table.v-table tbody th {
    height: 17px!important;
  } */

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 1.5rem !important;
  }

  ::v-deep .checkbox-label label {
    color: var(--COR_SECUNDARIA) !important;  
    font-size:14px !important;
  }

  .nav-link {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
  }
  

</style>


